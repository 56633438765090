<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Comparing archives</h3>

	<p>When <span v-html="link('archives', 'viewing archives')"></span> for a framework, click the <v-icon small>fas fa-exchange-alt</v-icon> button to compare an archived version of the framework to the <i>current</i> version of the framework.</p>
	<img alt="Comparing archives example image" srcset="/docimages/archive_compare-2.png 2x" class="k-help-img block">
	<p>The top of the archive compare dialog (above) will show the date the archive was taken, the archive description, and a set of filters that will determine which changes between the archived version and the current version should be shown:</p>
	<ul>
		<li>In the <b>Show</b> line, choose whether to show new entities, deleted entities, and/or updated entities (i.e. entities that exist in both the archive and the current framework, but have been changed at some time since the archive was taken).</li>
		<li>In the <b>For entity types</b> line, choose whether to show differences in the <span v-html="link('case_document', 'document')"></span>, framework <span v-html="link('case_items', 'items')"></span> (e.g. standards), and/or “isChildOf” or other types of <span v-html="link('case_associations', 'associations')"></span> (isChildOf associations determine where items appear in the framework hierarchy; other types of associations record various types of relationships between items).</li>
		<li>In the <b>With changes to fields</b> line, you can get as granular as you like as to which exact metadata fields you care about. For example, if you only care about changes to item’s Full Statements, you can first click “NONE” to clear all these fields, then click to show the Full Statement changes only. <ul>
			<li>Note: if you don’t see a checkbox for a certain metadata field, it means that no changes to that field have occurred since this archive was taken. For example, in the screenshot above, there is no checkbox for “Human-Readable Code”; this means that no items have had their human-readable codes changed since the time this archive was taken.</li>
		</ul></li>
		<li>Enter text in the search box to limit viewed changes to those that include the entered text.  For example, if you have changes to full statements shown and you enter “contrast” in the search box, only items with the word “contrast” in either the archived or current version of the full statement will be shown.</li>
		<li>If the “<b>Highlight textual differences</b>” box is checked, changes to the text between the archived and current versions will be highlighted in pink and blue, respectively. For example, in the screenshot below, we see that the words “story or” in the archived version were changed to “story” in the current version, and that the words “or poem” were added.</li>
	</ul>
	<img alt="Comparing archive details example image" srcset="/docimages/archive_compare-3.png 2x" class="k-help-img block">
	<p>The screenshot above shows an example entry where the full statement of an item has been updated at some point since the archive was taken.</p>
	<ul>
		<li>The “<b>Dates</b>” fields reflect the last change date for the item at the time the archive was taken (“Archive Date”), and the current last change date. Note that these dates may not be the same as the date the archive was taken or the current date. This can be confusing, so consider how these dates relate to each other for this example: <ul>
			<li>The framework archive was taken on 12/4/2021 (see top screenshot)</li>
			<li>But when the archive was taken, the last change date for this item indicates that it had last been changed considerably earlier, on 10/20/2020</li>
			<li>The current date—the date when this screenshot was taken—is 10/31/2022</li>
			<li>In the current version of the framework, the last change date for the item indicates that it was last changed on 10/7/2022.</li>
		</ul></li>
		<li>To view the full change history for any entity, and/or to <b>revert to a metadata field’s previous value</b> (if you have editing access to the framework) click the <v-icon small>fas fa-code</v-icon> code icon button to view the item’s metadata, then click the “SHOW CHANGE HISTORY” button. See the <span v-html="link('more_info', 'Item Metadata')"></span> help doc for more information.</li>
		<li>Click the maroon <v-icon small>fas fa-tree</v-icon> tree icon to <b>view an item “in context”</b>, in the archived version of the framework. Or click the green <v-icon small>fas fa-tree</v-icon> tree icon to view the item in the current version of the framework.</li>
	</ul>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	